import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [showTopbar, setShowTopbar] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowTopbar(false);
      } else {
        setShowTopbar(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showTopbar && (
        <Box component="div" sx={{ p: 1, backgroundColor: 'secondary.main', color: 'white', textAlign: 'center' }}>
          <Typography variant="body2">
            Need help? Call us: <Link to="tel:+16572981812" style={{ color: 'inherit', textDecoration: 'none' }}>+1 (657) 298-1812</Link> | Email: <Link to="mailto:hello@digicraftech.com" style={{ color: 'inherit', textDecoration: 'none' }}>hello@digicraftech.com</Link>
          </Typography>
        </Box>
      )}
      <AppBar position="sticky">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" className="navbar-logo">
              Digicraftech
            </Link>
          </Typography>
          <Button color="inherit" component={Link} to="/services">Services</Button>
          <Button color="inherit" component={Link} to="/portfolio">Portfolio</Button>
          <Button color="inherit" component={Link} to="/why-choose-us">Why Choose Us</Button>
          <Button color="inherit" component={Link} to="/packages">Packages</Button>
          <Button color="inherit" component={Link} to="/contact-us">Contact Us</Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;