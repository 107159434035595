import React from 'react';
import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './Services.css';

const Services = () => {
  return (
    <>
      <Navbar />
      <Box component="main" sx={{ p: 4 }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
          Tailored Solutions for Your Business Growth
        </Typography>

        {/* SEO Optimization Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Advanced SEO Strategies to Elevate Your Brand and Drive Targeted Traffic
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Our SEO services improve your website's visibility in search engines. We drive targeted traffic and enhance user engagement using cutting-edge tools to ensure your brand stands out in a crowded digital landscape.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Challenges Addressed:
              </Typography>
              <ul>
                <li>Low website visibility</li>
                <li>Difficulty attracting the right audience</li>
                <li>Low engagement and conversion rates</li>
              </ul>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Learn More About SEO
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Web Design & Development Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Crafting User-Centric, Stunning Web Experiences with a Focus on Performance and Branding
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                We don’t just build websites; we create experiences. Our responsive and user-centric designs ensure that your brand leaves a lasting impression. Whether you need a simple website or a complex web application, we deliver high-quality solutions tailored to your needs.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Challenges Addressed:
              </Typography>
              <ul>
                <li>Poor user experience and outdated design</li>
                <li>Non-responsive websites that don't adapt to different devices</li>
                <li>Lack of effective branding through web presence</li>
              </ul>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                View Our Web Design Portfolio
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Content Marketing Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Content That Connects, Converts, and Builds Lasting Relationships
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Our content marketing team crafts compelling, actionable content that educates, informs, and builds trust. We focus on creating value-driven content that transforms casual visitors into loyal customers and brand advocates.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Challenges Addressed:
              </Typography>
              <ul>
                <li>Lack of quality content to engage users</li>
                <li>Difficulty in establishing authority in your industry</li>
                <li>Low conversion rates due to ineffective communication</li>
              </ul>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Explore Our Content Marketing Services
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Social Media Marketing Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Building Buzz, Driving Engagement, and Delivering Tangible Results
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                We bring brands to life on social media platforms. By creating meaningful content and targeted campaigns, we engage your audience, build your brand identity, and drive measurable results that contribute to your overall business growth.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Challenges Addressed:
              </Typography>
              <ul>
                <li>Low brand visibility on social platforms</li>
                <li>Poor audience engagement and interaction</li>
                <li>Difficulty in building a consistent brand voice</li>
              </ul>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                See Our Social Media Strategies
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* E-commerce Optimization Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Maximize Sales with a High-Performance eCommerce Store and Seamless User Experience
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Our eCommerce optimization services focus on turning your store into a powerful sales engine. From user experience enhancements to technical optimizations, we ensure every click matters, making it easier for customers to complete their purchase journey.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Challenges Addressed:
              </Typography>
              <ul>
                <li>High cart abandonment rates</li>
                <li>Poor user navigation and product discoverability</li>
                <li>Low sales conversion rates</li>
              </ul>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Maximize Your eCommerce Sales
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Data Analytics Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Transforming Data into Actionable Customer and Marketing Insights
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                We provide advanced analytics tools to help you understand your audience better and make informed decisions. Our data analytics services empower you to optimize marketing efforts, improve customer experiences, and drive higher ROI.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Challenges Addressed:
              </Typography>
              <ul>
                <li>Lack of insights into customer behavior</li>
                <li>Ineffective decision-making due to lack of data</li>
                <li>Inability to measure marketing performance accurately</li>
              </ul>
              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Discover More About Data Analytics
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Services;
