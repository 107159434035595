import React from 'react';
import { Box, Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Box
      sx={{
        padding: { xs: 2, md: 4 },
        maxWidth: '800px',
        margin: '0 auto',
        textAlign: 'left',
      }}
    >
      <Typography variant="h3" sx={{ mb: 3 }}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Welcome to Digicraftech. Your privacy is very important to us. This Privacy Policy explains how we collect, use, and protect your personal information.
      </Typography>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        Information We Collect
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        We collect information that you provide directly to us, such as when you register, make a purchase, or communicate with us. We also automatically collect some information about your device and usage of our website.
      </Typography>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        We use the information we collect to provide, maintain, and improve our services. We may also use your information for customer support, marketing, and promotional purposes.
      </Typography>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        Sharing Your Information
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights.
      </Typography>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        Contact Us
      </Typography>
      <Typography variant="body1">
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:hello@digicraftech.com">hello@digicraftech.com</a>.
      </Typography>
    </Box>
  );
}

export default PrivacyPolicy;
