import React from 'react';
import { Box, Typography, Button,} from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './WhyChooseUs.css';

const WhyChooseUs = () => {
  return (
    <>
      <Navbar />
      <Box component="main" sx={{ p: 4 }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
          Why Choose Digicraftech: Your Partner in Digital Growth
        </Typography>

        {/* Key Benefits Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Key Benefits
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Tailored Strategies for Your Business
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We understand that every business is unique. That’s why we create custom strategies that fit your specific needs and goals. For example, we helped a local retailer boost their online presence by 200% through a targeted SEO campaign, using advanced keyword research, on-page optimization, and local SEO tactics to achieve these results. This shows how tailored strategies can make a significant impact.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            A Team You Can Trust
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Our digital marketing experts, designers, and developers are passionate about what they do. Our team members hold certifications in Google Analytics, HubSpot Content Marketing, and other industry-leading programs, ensuring your projects are in capable hands. We also engage in ongoing training and professional development to stay current with the latest industry trends. We bring creativity, experience, and dedication to every project, working closely with you to make your vision a reality.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Focused on Real Results
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We’re all about delivering results that matter. For instance, our recent campaign for a tech startup led to a 50% increase in conversions within three months. We track key metrics such as conversion rates, ROI, and customer acquisition to ensure our approach is always driven by measurable success.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            All-in-One Service
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            No need to juggle multiple providers. We offer everything from digital marketing to e-commerce solutions, app development, social media management, SEO, and content creation—all under one roof to keep things simple for you.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Global Perspective, Local Touch
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We blend international expertise with a deep understanding of local markets. For example, our campaign for a local business combined global SEO techniques with region-specific content, resulting in a 70% increase in local engagement. We also helped a healthcare provider leverage global marketing strategies while tailoring messaging to resonate locally, achieving a significant uptick in patient inquiries. We ensure your campaigns resonate with your target audience, wherever they are.
          </Typography>
        </Box>

        {/* Client Testimonials Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            What Our Clients Say About Us
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Our clients are at the heart of what we do. Here’s what they have to say about their experience with Digicraftech and how we've helped them achieve success. From healthcare to finance, our clients have experienced measurable growth and success with our tailored solutions.
          </Typography>

          <Typography variant="body2" sx={{ mb: 2 }}>
            "Working with Digicraftech has been amazing. Their SEO and content marketing strategies completely transformed our online presence. We saw a huge increase in traffic." - John D., CEO, ABC Corp
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "Digicraftech made a real difference for our e-commerce store. They revamped our user experience, and we saw our sales soar. They’re the real deal." - Sarah M., Owner, XYZ Fashion
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "The Digicraftech team took the time to understand our business and delivered beyond our expectations. They’re knowledgeable, easy to work with, and results-driven." - Alex R., Marketing Director, LMN Startup
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "Their SEO expertise is unmatched. We went from being buried in search results to ranking on the first page. It has had a huge impact on our lead generation." - Michael K., Owner, Local Biz
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "Digicraftech built us a stunning website that truly represents our brand. The design is modern, user-friendly, and has helped us attract more clients." - Emily T., Founder, Creative Co.
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "The content they created for us was spot on. Engaging, informative, and perfectly aligned with our brand voice. We saw a significant increase in user engagement." - Nina L., Marketing Manager, Green Solutions
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "Their social media campaigns brought our brand to life. We saw incredible growth in followers and engagement. The campaigns were creative and really resonated with our audience." - David R., CEO, Trendy Apparel
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "Digicraftech’s e-commerce setup and support made a huge difference for our online store. They streamlined everything and provided ongoing support that keeps us running smoothly." - Karen S., Owner, Home Essentials
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            "They designed and developed a mobile app for us that’s both visually appealing and easy to use. The whole process was seamless, and the final product exceeded our expectations." - Raj P., Co-Founder, FitnessPro
          </Typography>
        </Box>

        {/* Experience & Expertise Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Our Experience Speaks for Itself
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            With over 10 years of experience, we’ve partnered with businesses in industries such as retail, healthcare, technology, and finance to help them thrive in the digital landscape. Notably, in our fifth year, we were recognized as a leading agency for innovative digital solutions by XYZ Magazine.
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We’ve successfully completed more than 500 projects, ranging from building dynamic e-commerce platforms to running full-scale, data-driven marketing campaigns. One notable success includes our work with a national retail chain, where our digital strategies contributed to a 40% increase in annual sales. Our experience ensures tailored solutions for each client’s unique needs.
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Our team is made up of certified professionals in SEO, content marketing, web development, and more. Our experts are certified by Google, HubSpot, and other leading organizations. For example, our lead SEO specialist recently received advanced certification in technical SEO, enabling us to offer even more effective strategies. We’re always learning and staying up-to-date with the latest industry trends to keep our clients ahead of the curve.
          </Typography>
        </Box>

        {/* Our Commitment Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            A Commitment to Excellence and Integrity
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We once helped a non-profit client revamp their entire online presence, all while maintaining transparency and a client-first approach, which ultimately led to a 300% boost in their donations. Key takeaways from this success story include our dedication to open communication, personalized strategies, and a focus on creating impactful results. We believe in creating strong, long-term relationships with our clients. Honesty, open communication, and dedication are at the core of our work. We always go above and beyond to ensure that you’re not just satisfied—but genuinely thrilled with our services.
          </Typography>
        </Box>

        {/* Call to Action Section */}
        <Box component="section" sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Ready to Work with a Team that Puts Your Success First?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Get started today and see how our tailored strategies can make a difference for your business. Our experienced consultants are here to guide you every step of the way. Limited consultation slots available—act now to secure your spot!
          </Typography>
          <Button variant="contained" color="primary">
            Get in Touch Today
          </Button>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default WhyChooseUs;
