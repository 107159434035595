// src/components/CookieConsent.js

import React, { useState } from 'react';
import { Box, Button, Drawer, IconButton, Switch, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';

const CookieConsent = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <>
      {/* Floating Icon Button for Managing Preferences */}
      <IconButton
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'secondary.main',
          color: 'white',
          zIndex: 1000,
          '&:hover': {
            backgroundColor: 'secondary.dark',
          },
        }}
        onClick={toggleDrawer(true)}
      >
        <Settings />
      </IconButton>

      
      {/* Drawer for Cookie Preferences */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 350, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Storage Preferences
          </Typography>

          {/* Cookie Category - Essential */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
            <Typography variant="body1">Essential</Typography>
            <Switch checked disabled />
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Required to enable basic website functionality. You may not disable essential cookies.
          </Typography>

          {/* Cookie Category - Targeted Advertising */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
            <Typography variant="body1">Targeted Advertising</Typography>
            <Switch defaultChecked />
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Used to deliver advertising that is more relevant to you and your interests.
          </Typography>

          {/* Cookie Category - Personalisation */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
            <Typography variant="body1">Personalisation</Typography>
            <Switch defaultChecked />
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Allow the website to remember choices you make to provide enhanced, more personal features.
          </Typography>

          {/* Cookie Category - Analytics */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
            <Typography variant="body1">Analytics</Typography>
            <Switch defaultChecked />
          </Box>
          <Typography variant="body2">
            Help the website operator understand how its website performs, how visitors interact with the site, and
            whether there may be technical issues.
          </Typography>

          {/* Save Button */}
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ mt: 4 }}
            onClick={() => {
              alert('Preferences Saved');
              setIsDrawerOpen(false);
            }}
          >
            Save Preferences
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default CookieConsent;
