import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeroSection from './HeroSection'; // Imported HeroSection component
import './Homepage.css';

const Homepage = () => {
  return (
    <>
      <Navbar />
      <HeroSection /> {/* Added HeroSection component after Navbar */}
      <Box component="main" sx={{ p: 4 }}>
        {/* Core Values Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Our Values: Driving Your Success
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Innovation
                  </Typography>
                  <Typography variant="body2">
                    Creativity and forward-thinking solutions are at the heart of what we do.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Collaboration
                  </Typography>
                  <Typography variant="body2">
                    Your goals become our mission. Together, we craft success stories.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Integrity
                  </Typography>
                  <Typography variant="body2">
                    Guided by transparency and trust in every decision.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Excellence
                  </Typography>
                  <Typography variant="body2">
                    We go above and beyond to deliver unmatched quality.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Our Vision & Mission Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Paving the Way for Digital Transformation
          </Typography>
          <Typography variant="h6" gutterBottom>
            Vision Statement
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            To be a trusted partner in driving the digital transformation of businesses through services like SEO, e-commerce solutions, and app development, empowering them to achieve their full potential.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Mission Statement
          </Typography>
          <Typography variant="body2">
            To harness the power of data, creativity, and technology to create impactful digital experiences that resonate with audiences, inspire trust, and drive conversions.
          </Typography>
        </Box>

        {/* About Us Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Our Story
          </Typography>
          <Typography variant="body2">
            Digicraftech began with a simple idea: to bridge the gap between creativity and strategy in the digital world. Today, we help brands thrive by offering tailored SEO, content marketing, e-commerce setup, video editing, and app development services, creating impactful digital experiences.
          </Typography>
        </Box>

        {/* Our Services Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Our Services
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            We offer a diverse range of digital services designed to address your unique business challenges and drive measurable results.
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    SEO Optimization
                  </Typography>
                  <Typography variant="body2">
                    Advanced strategies to increase visibility, attract the right audience, and drive meaningful engagement.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Web Design & Development
                  </Typography>
                  <Typography variant="body2">
                    We create responsive, user-centric, and visually stunning websites that leave a lasting impression.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Content Marketing
                  </Typography>
                  <Typography variant="body2">
                    Compelling content that educates, informs, and transforms visitors into loyal customers.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Social Media Marketing
                  </Typography>
                  <Typography variant="body2">
                    We bring your brand to life on social platforms, driving buzz and engagement.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    E-commerce Setup & Maintenance
                  </Typography>
                  <Typography variant="body2">
                    End-to-end solutions for setting up your online store and providing ongoing support to ensure its success.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Video Editing & CGI
                  </Typography>
                  <Typography variant="body2">
                    Create high-quality, visually appealing videos with CGI effects to enhance your brand storytelling.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Mobile Application UI/UX Design & Development
                  </Typography>
                  <Typography variant="body2">
                    Custom app designs and development that offer seamless user experiences across platforms.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Data Analytics
                  </Typography>
                  <Typography variant="body2">
                    Gain actionable insights to make data-driven decisions that optimize performance and ROI.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Call to Action Section */}
        <Box component="section" sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Ready to Transform Your Digital Presence?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Limited-Time Offer: Get a Free Consultation Today to Boost Your Business Online!
          </Typography>
          <Button variant="contained" color="primary">
            Contact Us Today
          </Button>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Homepage;
