import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import './Footer.css';

const Footer = () => {
  return (
    <Box component="footer" sx={{ p: 4, backgroundColor: 'primary.light', color: 'black' }}>
      <Grid container spacing={4}>
        {/* Company Info Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Digicraftech
          </Typography>
          <Typography variant="body2">
            Digicraftech - outsourcing web and mobile application development company. © Copyright 2015-2024
          </Typography>
        </Grid>

        {/* Overview Section */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" gutterBottom>
            Overview
          </Typography>
          <Link href="/" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Home</Link>
          <Link href="/our-story" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Our Story</Link>
          <Link href="/technologies" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Technologies</Link>
          <Link href="/faq" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>FAQ</Link>
          <Link href="/privacy-policy" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Privacy Policy</Link>
          <Link href="/contacts" color="inherit" underline="none" sx={{ display: 'block' }}>Contacts</Link>
        </Grid>

        {/* Development Section */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" gutterBottom>
            Development
          </Typography>
          <Link href="/web" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Web</Link>
          <Link href="/mobile" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Mobile</Link>
          <Link href="/on-demand-apps" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>On-Demand Apps</Link>
          <Link href="/ios" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>iOS</Link>
          <Link href="/android" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Android</Link>
          <Link href="/flutter" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Flutter</Link>
          <Link href="/wearable" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Wearable</Link>
          <Link href="/design" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Design</Link>
          <Link href="/discovery-stage" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Discovery Stage</Link>
          <Link href="/mvp-development" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>MVP Development</Link>
          <Link href="/php" color="inherit" underline="none" sx={{ display: 'block' }}>PHP</Link>
        </Grid>

        {/* Areas of Expertise Section */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" gutterBottom>
            Areas of Expertise
          </Typography>
          <Link href="/social-media-platform" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Social Media Platform</Link>
          <Link href="/sports-fitness-apps" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Sports and Fitness App</Link>
          <Link href="/job-board-apps" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Job Board Apps</Link>
          <Link href="/educational-websites" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Educational Websites</Link>
          <Link href="/saas" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Software as a Service (SaaS)</Link>
          <Link href="/travel-booking-portals" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Travel & Booking Portals</Link>
          <Link href="/trading-systems" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Trading Systems</Link>
          <Link href="/iot" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Internet of Things</Link>
          <Link href="/logistics" color="inherit" underline="none" sx={{ display: 'block' }}>Logistics Software Solutions</Link>
        </Grid>

        {/* Popular Requests Section */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" gutterBottom>
            Popular Requests
          </Typography>
          <Link href="/whatsapp-clone" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>WhatsApp like App</Link>
          <Link href="/uber-clone" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Uber like App</Link>
          <Link href="/kik-clone" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Kik up like App</Link>
          <Link href="/snapchat-clone" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Snapchat like App</Link>
          <Link href="/expedia-clone" color="inherit" underline="none" sx={{ display: 'block' }}>Expedia like App</Link>
        </Grid>

        {/* Affiliate Program and Contact Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Affiliate Program
          </Typography>
          <Link href="/partners" color="inherit" underline="none" sx={{ display: 'block', mb: 2 }}>For partners</Link>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            +1 123-456-7890
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            hello@digicraftech.com
          </Typography>
          <Typography variant="body2">
            4111 Scenic Valley Ln, Sugar Land, TX 77479
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
