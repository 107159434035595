import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Homepage from './pages/Homepage';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import Portfolio from './pages/Portfolio';
import WhyChooseUs from './pages/WhyChooseUs';
import Packages from './pages/Packages';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookieConsent from './components/CookieConsent'; // Importing CookieConsent
import './App.css';
import './styles/global.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1D2442', // Deep Navy Blue
    },
    secondary: {
      main: '#FF6600', // Bright Orange
    },
    background: {
      default: '#D4E4FF', // Pale Blue
    },
  },
  typography: {
    fontFamily: 'Montserrat, Roboto, sans-serif',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/why-choose-us" element={<WhyChooseUs />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <CookieConsent /> {/* Added CookieConsent component */}
      </Router>
    </ThemeProvider>
  );
};

export default App;
  