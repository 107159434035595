import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './Portfolio.css';

const Portfolio = () => {
  return (
    <>
      <Navbar />
      <Box component="main" sx={{ p: 4 }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
          Showcase of Success Stories
        </Typography>

        {/* Showcase of Projects Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Our Projects
          </Typography>
          <Typography variant="h6" gutterBottom>
            Delivering Digital Excellence with Proven Results for Clients Across the Globe
          </Typography>

          <Grid container spacing={4}>
            {/* DEF Retail Project */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt="DEF Retail Project Thumbnail"
                  height="140"
                  image="/images/def-retail-thumbnail.jpg"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    DEF Retail
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    E-commerce Setup & Maintenance
                  </Typography>
                  <Button variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* GHI Media Project */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt="GHI Media Project Thumbnail"
                  height="140"
                  image="/images/ghi-media-thumbnail.jpg"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    GHI Media
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Video Editing & CGI
                  </Typography>
                  <Button variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* JKL Health Project */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt="JKL Health Project Thumbnail"
                  height="140"
                  image="/images/jkl-health-thumbnail.jpg"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    JKL Health
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Mobile Application UI/UX Design & Development
                  </Typography>
                  <Button variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* ABC Corp Project */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt="ABC Corp Project Thumbnail"
                  height="140"
                  image="/images/abc-corp-thumbnail.jpg"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    ABC Corp
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    SEO Optimization & Content Marketing
                  </Typography>
                  <Button variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* XYZ Fashion Project */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt="XYZ Fashion Project Thumbnail"
                  height="140"
                  image="/images/xyz-fashion-thumbnail.jpg"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    XYZ Fashion
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Web Design & E-commerce Optimization
                  </Typography>
                  <Button variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* LMN Startup Project */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt="LMN Startup Project Thumbnail"
                  height="140"
                  image="/images/lmn-startup-thumbnail.jpg"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    LMN Startup
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Social Media Marketing & Data Analytics
                  </Typography>
                  <Button variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Client Success Stories Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Client Testimonials
          </Typography>
          <Typography variant="body2">
            "Digicraftech helped our business thrive by delivering top-notch SEO and content marketing services. Our visibility increased significantly, and we couldn't be happier!" - ABC Corp
          </Typography>
        </Box>

        {/* Categories Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Explore by Service Area
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                SEO Projects
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                Web Design & Development
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                Content Marketing
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                Social Media Campaigns
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                E-commerce Setup & Maintenance
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                Video Editing & CGI
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                Mobile App Design & Development
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button variant="outlined" color="primary" fullWidth>
                Data Analytics Transformations
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* Call to Action Section */}
        <Box component="section" sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Ready to Join Our Success Stories?
          </Typography>
          <Button variant="contained" color="primary">
            Get in Touch to Start Your Project
          </Button>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Portfolio;
