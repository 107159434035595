import React from 'react';
import { Box, Typography, Button, TextField, Grid,} from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <Box component="main" sx={{ p: 4 }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
          Get in Touch with Digicraftech
        </Typography>

        {/* Introduction Paragraph Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We’d love to hear from you! Whether you have a question about our services like digital marketing, e-commerce solutions, app development, or content creation, want to discuss your next big project, or need help finding the right solution for your business, our team is here to help. Let’s shape the future of your business together in the era of limitless digital possibilities.
          </Typography>
        </Box>

        {/* Contact Form Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Reach Out to Us
          </Typography>
          <Box component="form" sx={{ mt: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Name" required variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Email Address" required variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Phone Number" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Subject" required variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Message" required multiline rows={4} variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Preferred Contact Method" select variant="outlined" SelectProps={{ native: true }}>
                  <option value="">None</option>
                  <option value="email">Email</option>
                  <option value="phone">Phone</option>
                  <option value="video">Video Call</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" type="submit">
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Contact Information Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Our Contact Details
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Email: info@digicraftech.com
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Phone: +1 123-456-7890
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Address: 4111 Scenic Valley Ln, Sugar Land, TX 77479
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Video Consultation: Schedule a call directly with our experts for a more personalized experience. Available for digital marketing, e-commerce setup, app development, and more.
          </Typography>
        </Box>

        {/* Map Integration Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Visit Our Office or Connect Virtually
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Our office is located in the heart of Sugar Land, Texas. Feel free to drop by for a chat, or schedule a virtual meeting if you prefer connecting online.
          </Typography>
          <Box sx={{ height: '400px', mb: 4 }}>
            {/* Embedded Google Map Widget Placeholder */}
            <iframe
              title="Digicraftech Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3478.155742237369!2d-95.6327274849047!3d29.599937282056826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d0d68f70f4f5%3A0x89d9b4b1c262f9db!2s4111%20Scenic%20Valley%20Ln%2C%20Sugar%20Land%2C%20TX%2077479%2C%20USA!5e0!3m2!1sen!2sus!4v1605488055918!5m2!1sen!2sus"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </Box>
        </Box>

        {/* Social Media Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Follow Us & Stay Updated
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Stay connected with us on social media for the latest updates, industry news, and expert tips. On LinkedIn, expect professional insights; on Instagram, see our creative work; on Twitter, catch up on industry news; and on YouTube, watch our tutorials and success stories.
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="outlined" color="primary" href="[LinkedIn URL]">
                LinkedIn
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" href="[Facebook URL]">
                Facebook
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" href="[Twitter URL]">
                Twitter
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" href="[Instagram URL]">
                Instagram
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" href="[YouTube URL]">
                YouTube
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* Call to Action Section */}
        <Box component="section" sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Ready to Start Your Digital Transformation?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            The future is digital, and we're here to make it happen. Let’s work together to bring your vision to life.
          </Typography>
          <Button variant="contained" color="primary">
            Schedule a Consultation
          </Button>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ContactUs;
