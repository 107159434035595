import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './Packages.css';

const Packages = () => {
  return (
    <>
      <Navbar />
      <Box component="main" sx={{ p: 4 }}>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
          Tailored Solutions for Every Business
        </Typography>

        {/* Service Packages Overview Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Choose the Perfect Package for Your Business Needs
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Our range of packages is designed to suit businesses of all sizes and requirements, ensuring you get the right solution for your growth.
          </Typography>
        </Box>

        {/* SEO Packages Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h5" gutterBottom>
            SEO Packages
          </Typography>
          <Grid container spacing={4}>
            {/* Basic SEO Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Basic SEO Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Perfect for startups and small businesses looking to establish a foundational online presence.
                  </Typography>
                  <ul>
                    <li>Keyword research and optimization</li>
                    <li>On-page SEO for up to 5 pages</li>
                    <li>Monthly performance report</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $500/month
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Get Started with Basic SEO
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Standard SEO Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Standard SEO Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Ideal for growing businesses that need more comprehensive SEO strategies.
                  </Typography>
                  <ul>
                    <li>Keyword research and content strategy</li>
                    <li>On-page and off-page SEO</li>
                    <li>Local SEO optimization</li>
                    <li>Monthly performance and analytics report</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $1,000/month
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Boost Your Visibility
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Premium SEO Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Premium SEO Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    A complete SEO solution for established businesses seeking to dominate their niche.
                  </Typography>
                  <ul>
                    <li>Advanced keyword strategy and competitive analysis</li>
                    <li>On-page, off-page, and technical SEO</li>
                    <li>Content creation and backlink building</li>
                    <li>Custom analytics and performance tracking</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $2,500/month
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Dominate Your Market
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Web Design & Development Packages Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h5" gutterBottom>
            Web Design & Development Packages
          </Typography>
          <Grid container spacing={4}>
            {/* Basic Web Design Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Basic Web Design Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    A clean, professional website design for small businesses.
                  </Typography>
                  <ul>
                    <li>Up to 5 pages</li>
                    <li>Responsive design</li>
                    <li>Basic SEO setup</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $1,500
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Create Your Website
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Standard Web Design Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Standard Web Design Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    A feature-rich website tailored to your brand and business needs.
                  </Typography>
                  <ul>
                    <li>Up to 10 pages</li>
                    <li>Custom design elements</li>
                    <li>Mobile responsiveness and SEO optimization</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $3,000
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Enhance Your Digital Presence
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Premium Web Development Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Premium Web Development Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    A fully customized web solution with advanced features for a truly engaging experience.
                  </Typography>
                  <ul>
                    <li>Unlimited pages</li>
                    <li>E-commerce integration</li>
                    <li>Custom functionalities and API integration</li>
                    <li>Advanced SEO setup</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $5,500
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Build a Unique Experience
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Content Marketing Packages Section */}
        <Box component="section" sx={{ mb: 6 }}>
          <Typography variant="h5" gutterBottom>
            Content Marketing Packages
          </Typography>
          <Grid container spacing={4}>
            {/* Starter Content Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Starter Content Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Essential content services for establishing a consistent brand voice.
                  </Typography>
                  <ul>
                    <li>4 blog posts per month</li>
                    <li>Social media content calendar</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $800/month
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Start Engaging Your Audience
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Growth Content Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Growth Content Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Comprehensive content solutions for growing your brand's influence.
                  </Typography>
                  <ul>
                    <li>8 blog posts per month</li>
                    <li>Social media content and email newsletters</li>
                    <li>Content strategy consultation</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $1,500/month
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Grow Your Influence
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* Premium Content Package */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Premium Content Package
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    A full-fledged content strategy and creation package for maximum impact.
                  </Typography>
                  <ul>
                    <li>12 blog posts per month</li>
                    <li>Social media content, email marketing, and video scripts</li>
                    <li>Comprehensive content marketing strategy</li>
                  </ul>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Price: Starting at $2,500/month
                  </Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Maximize Your Reach
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Call to Action Section */}
        <Box component="section" sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" gutterBottom>
            Not Sure Which Package is Right for You?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Our team is here to help you choose the best option for your business goals.
          </Typography>
          <Button variant="contained" color="primary">
            Contact Us for a Free Consultation
          </Button>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Packages;
