import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Stack } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

function HeroSection() {
  // Slideshow Messages
  const messages = [
    "Empowering your business to succeed.",
    "Technology that drives your growth.",
    "Your partner in digital transformation.",
    "A new portal to digital marketing success.",
  ];

  // State for current message index
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  // Effect to cycle through messages every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [messages.length]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
        position: 'relative',
        padding: 2,
        overflow: 'hidden',
      }}
    >
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
          opacity: 0.6,
        }}
      >
        <source src="/assets/Video/hero-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Animated Hero Heading */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, ease: 'easeOut' }}
        style={{ zIndex: 1 }}
      >
<Typography
  variant="h1"
  sx={{
    mb: 2,
    fontSize: { xs: '3rem', md: '5rem' },
    fontWeight: 'bold',
    background: 'linear-gradient(90deg, #FF4500, #FFD700, #32CD32)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'solid',
  textShadow: '0 0 10px #FFFFFF, 0 0 20px #FFD700, 0 0 30px #FF6A00', // Stronger and larger shadow for better contrast
  }}
>
  Welcome to Your Future
</Typography>


        </motion.div>

      {/* Subheading with Motion Effect */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.8, ease: 'easeOut' }}
        style={{ zIndex: 1 }}
      >
        <Typography variant="h5" sx={{ mb: 4, fontSize: { xs: '1.5rem', md: '2rem' }, fontStyle: 'italic' }}>
          Innovative solutions for a modern world.
        </Typography>
      </motion.div>

      {/* Animated Slideshow Text */}
      <Box sx={{ zIndex: 1, height: '40px', mb: 4, overflow: 'hidden', position: 'relative' }}>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentMessageIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 1, ease: 'anticipate' }}
            style={{ position: 'absolute', width: '100%' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: '1.2rem', md: '1.5rem' },
                fontStyle: 'italic',
                color: '#FFD700', // Gold color for better contrast
                textShadow: '1px 1px 10px rgba(0, 0, 0, 0.6)', // Subtle shadow for readability
              }}
            >
              {messages[currentMessageIndex]}
            </Typography>
          </motion.div>
        </AnimatePresence>
      </Box>

      {/* Email Input Field with Staggered Animation */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, ease: 'easeOut' }}
        style={{ zIndex: 1 }}
      >
        <TextField
          placeholder="Enter your email to stay updated"
          variant="outlined"
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 1,
            mb: 3,
            width: { xs: '80%', md: '400px' },
          }}
        />
      </motion.div>

      {/* Action Buttons with Interactive Effect */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2.5, ease: 'easeOut' }}
        style={{ zIndex: 1 }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.15)',
                backgroundColor: '#ff9800',
              },
            }}
          >
            Get Started Now
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            sx={{
              borderColor: 'white',
              color: 'white',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            Learn More
          </Button>
        </Stack>
      </motion.div>
    </Box>
  );
}

export default HeroSection;
